"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
class LayerPreview extends react_1.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.updateCanvas();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.layer != this.props.layer)
            this.updateCanvas();
    }
    updateCanvas() {
        this.props.layer.draw(this.refs.canvas);
    }
    render() {
        return (react_1.default.createElement("div", { style: { top: 0, left: 0, pointerEvents: 'none' } },
            react_1.default.createElement("canvas", { ref: 'canvas' })));
    }
}
exports.default = LayerPreview;
