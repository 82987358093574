"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function loadFileContent(file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = () => {
            const result = reader.result;
            if (typeof result == 'string')
                resolve(result);
            else
                reject();
        };
        reader.onerror = reject;
        reader.readAsText(file);
    });
}
exports.default = loadFileContent;
