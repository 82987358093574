"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
require("../styles/Crosshair.css");
class Crosshair extends react_1.Component {
    constructor(props) {
        super(props);
        this.state = {
            left: true,
            top: true
        };
    }
    componentDidUpdate(prevProps) {
        if (this.state.left && this.props.x > this.props.width * 0.66)
            this.setState({ left: false });
        else if (!this.state.left && this.props.x < this.props.width * 0.33)
            this.setState({ left: true });
        if (this.state.top && this.props.y > this.props.height * 0.66)
            this.setState({ top: false });
        else if (!this.state.top && this.props.y < this.props.height * 0.33)
            this.setState({ top: true });
    }
    render() {
        return (react_1.default.createElement("div", { className: 'crosshair' },
            react_1.default.createElement("svg", { style: { position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', pointerEvents: 'none' } },
                react_1.default.createElement("rect", { x: 0, y: this.props.y, width: '100%', height: 1 }),
                react_1.default.createElement("rect", { x: this.props.x, y: 0, width: 1, height: '100%' })),
            react_1.default.createElement("div", { className: 'location', style: {
                    left: this.state.left ? this.props.x : undefined,
                    right: this.state.left ? undefined : this.props.width - this.props.x,
                    top: this.state.top ? undefined : 0,
                    bottom: this.state.top ? 0 : undefined,
                } }, this.props.x),
            react_1.default.createElement("div", { className: 'location', style: {
                    left: this.state.left ? undefined : 0,
                    right: this.state.left ? 0 : undefined,
                    top: this.state.top ? this.props.y : undefined,
                    bottom: this.state.top ? undefined : this.props.height - this.props.y
                } }, this.props.y)));
    }
}
exports.default = Crosshair;
