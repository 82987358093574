"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function loadShader(gl, type, source) {
    const shader = gl.createShader(type);
    if (shader == null)
        throw Error();
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS))
        throw Error('An error occurred compiling the shaders:\n' + gl.getShaderInfoLog(shader));
    return shader;
}
exports.default = loadShader;
