"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class RasterImage {
    constructor() {
        this._canvas = document.createElement('canvas');
    }
    load(image) {
        this.width = image.width;
        this.height = image.height;
        this.imageContext.drawImage(image, 0, 0);
    }
    get imageContext() {
        const context = this._canvas.getContext('2d');
        if (context == null)
            throw new Error('Browser is not supported');
        return context;
    }
    get glContext() {
        const context = this._canvas.getContext('webgl');
        if (context == null)
            throw new Error('Browser is not supported');
        return context;
    }
    get width() {
        return this._canvas.width;
    }
    set width(value) {
        this._canvas.width = value;
    }
    get height() {
        return this._canvas.height;
    }
    set height(value) {
        this._canvas.height = value;
    }
    get canvas() {
        return this._canvas;
    }
    get imageData() {
        return this.imageContext.getImageData(0, 0, this.width, this.height);
    }
    get url() {
        return this.canvas.toDataURL();
    }
}
exports.default = RasterImage;
