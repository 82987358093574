"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function serializeShaders(shaders) {
    return JSON.stringify(shaders);
}
exports.serializeShaders = serializeShaders;
function deserializeShaders(content) {
    return JSON.parse(content);
}
exports.deserializeShaders = deserializeShaders;
