"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
class LayerData extends react_1.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.selected = this.selected.bind(this);
        this.edit = this.edit.bind(this);
    }
    selected() {
        this.props.onSelected(this.props.layer);
    }
    edit(e) {
        this.props.onEdit(this.props.layer);
    }
    render() {
        return (react_1.default.createElement("div", { className: `layer-data ${this.props.isSelected && 'selected'} ${this.props.layer.renderingError && 'error'} ${this.props.isDisabled && 'disabled'}`, onClick: this.selected, title: this.props.layer.renderingError },
            react_1.default.createElement("div", { className: 'layer-header' },
                react_1.default.createElement("div", { className: 'layer-name' }, this.props.layer.shader.name),
                react_1.default.createElement("div", { className: 'edit', onClick: this.edit }, "\uD83D\uDD89")),
            this.props.position &&
                this.getValues(this.props.position.x, this.props.position.y),
            !this.props.position &&
                react_1.default.createElement("div", { className: 'layer-values' },
                    react_1.default.createElement("div", { className: 'layer-value placeholder' }, "000"),
                    react_1.default.createElement("div", { className: 'layer-value placeholder' }, "000"),
                    react_1.default.createElement("div", { className: 'layer-value placeholder' }, "000"))));
    }
    getValues(x, y) {
        const value = this.props.layer.getValue(x, y);
        if (value.r == value.g && value.g == value.b)
            return this.getSingleValue(value.r);
        else
            return this.getThreeValues(value.r, value.g, value.b);
    }
    getSingleValue(value) {
        return (react_1.default.createElement("div", { className: 'layer-values' },
            this.getValue(value, 'placeholder'),
            this.getValue(value, 'gray'),
            this.getValue(value, 'placeholder')));
    }
    getThreeValues(r, g, b) {
        return (react_1.default.createElement("div", { className: 'layer-values' },
            this.getValue(r, 'red'),
            this.getValue(g, 'green'),
            this.getValue(b, 'blue')));
    }
    getValue(value, className) {
        if (!value)
            value = 0;
        let str = value.toString();
        while (str.length < 3)
            str = `0${str}`;
        return react_1.default.createElement("div", { className: `layer-value ${className}` }, str);
    }
}
exports.default = LayerData;
